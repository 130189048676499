@import url("https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap");

/* :root {
  --primary: #212529;
  --secondary: #fff;
} */

h1 {
  font-family: "Inter", sans-serif;
}

p {
  font-family: "Inter", sans-serif;
}

.p-font {
  font-family: "Inter", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.text-large {
  font-size: 800%;
}
/* p {
  margin: 20px 0;
} */

/* page content */
/* main { */
/* max-width: 60%; */
/* margin: auto;
} */

.center-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-title {
  font-size: larger;
}

.font__size {
  font-size: large;
}

.contact {
  font-size: large;
}
p {
  margin-bottom: 40px;
}

.contact__form .form-control {
  padding: 0.5rem 0.5rem;
  line-height: 1.5;
  border-radius: 0 !important;
  border: 1px solid;
}

.ac_btn {
  padding: 0.5rem 2rem;
}

.gallery {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-width: 33%;
  -moz-column-width: 33%;
  column-width: 33%;
  padding: 0 12px;
}

.gallery .pics {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  cursor: pointer;
  margin-bottom: 12px;
}

.gallery1 {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-width: 33%;
  -moz-column-width: 33%;
  column-width: 33%;
  padding: 0 12px;
}

.gallery1 .pics1 {
  transition: all 350ms ease;
  margin-bottom: 12px;
}

.gallery .pics:hover {
  filter: opacity(0.9);
}

.profile-pic {
  width: 32rem;
  height: 32rem;
}

@media (max-width: 992px) {
  .gallery {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }

  .gallery1 {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }

  .text-large {
    font-size: 700%;
  }

  .profile-pic {
    width: 26rem;
    height: 26rem;
  }
}

@media (max-width: 768px) {
  .text-large {
    font-size: 500%;
  }

  .profile-pic {
    width: 22rem;
    height: 22rem;
  }

  .ishidden {
    display: none;
  }
}

@media (max-width: 576px) {
  .text-large {
    font-size: 400%;
  }

  .profile-pic {
    width: 18rem;
    height: 18rem;
  }
}

@media (max-width: 480px) {
  .gallery,
  .gallery1 {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-width: 100%;
    -moz-column-width: 100%;
    column-width: 100%;
  }

  .testi {
    margin-bottom: 1rem;
  }

  .text-large {
    font-size: 300%;
  }

  .lets-talk {
    font-size: x-large;
  }

  .ishidden {
    display: none;
  }
}
